import axios from 'axios'

import { API_BASE_URL } from "configs/AppConfig";
import { SIGNOUT_SUCCESS } from "../redux/constants/Auth";

const axiosUtil = {
    initalise: (store) => {
        axios.defaults.baseURL = API_BASE_URL
        // Request Interceptor. All Request pass from here
        axios.interceptors.request.use(
            axiosConfig => {
                const authToken = localStorage.getItem('AUTH_TOKEN')
                if (authToken) {
                    axiosConfig.headers['Authorization'] = `${localStorage.getItem('AUTH_TOKEN')}`
                }
                axiosConfig.headers['Content-Type'] = 'application/json'
                return axiosConfig
            },
            error => {
                Promise.reject(error)
            })

        /*
            Response Interceptor
            Responsibilities:
            1- If api sends new token then change the store auth token with new token
            2- If api sends 401 token then send user to login page
        */
        axios.interceptors.response.use((response) => {
            // const newToken = get(response, 'headers.x-token', '')
            // if(newToken) {
            //     localStorage.setItem("AUTH_TOKEN", newToken);
            // }
            return response
        }, function (error) {
            // const originalRequest = error.config
            if(error.response.data.is_logout === 1){
                localStorage.clear();
                store.dispatch({
                    type: SIGNOUT_SUCCESS,
                });
            }
            return Promise.reject(error)
        })
    }
}

export default axiosUtil