import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";

export const Views = (props) => {
  const { locale, location, token } = props;
  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
          { token != null ?
                <AppLayout location={location} /> :
                <AuthLayout />
          }
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
