import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Map from './Map';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    map:Map
});

export default reducers;