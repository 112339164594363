import {
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  UPDATE_ROLE,
  STORE_INFORMATION,
  STORE_INFORMATION_SUCCESS,
  STORE_PERMISSON_INFORMATION,
  STORE_OTHER_INFORMATION,
  STORE_DRIVER_INFORMATION,
  TOKEN_INFORMATION
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem('AUTH_TOKEN'),
  userInformation: JSON.parse(localStorage.getItem("user_detail")),
  roleInformation: JSON.parse(localStorage.getItem("permission")),
  otherDocument: JSON.parse(localStorage.getItem('document')),
  driverDocument: JSON.parse(localStorage.getItem('driver')),
  tokenInfo: localStorage.getItem('isLogout'),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case TOKEN_INFORMATION: {
      return {
        ...state,
        tokenInfo: action.payload,
      };
    }
    case STORE_INFORMATION: {
      return {
        ...state,
        userInformation: action.payload,
      };
    }
    case STORE_OTHER_INFORMATION: {
      return {
        ...state,
        otherDocument: action.payload,
      };
    }
    case STORE_DRIVER_INFORMATION: {
      return {
        ...state,
        driverDocument: action.payload,
      };
    }
    case STORE_PERMISSON_INFORMATION: {
      return {
        ...state,
        roleInformation: action.payload,
      };
    }
    case STORE_INFORMATION_SUCCESS: {
      return {
        ...state,
        userInformation: action.payload,
      };
    }
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_ROLE :{
      return {
        ...state,
        roleInformation: action.payload
      }
    }
    default:
      return state;
  }
};

export default auth;
