import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  LogoutOutlined,
  SolutionOutlined,
  ContactsOutlined,
  KeyOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { APP_PREFIX_PATH } from "configs/AppConfig";
import { getPermissionList } from "../../services/apiConstant";
import { authenticated, storePermissionInformation } from "redux/actions/Auth";
import { getDataFromApi } from "views/api";

export const NavProfile = () => {
  const profile = useSelector((state) => state.auth.userInformation);
  const [permission,setPermission ]= useState([]);
  const [roleState, setRoleState] = useState(false);
  const [staffState, setStaffState] = useState(false);
  const [settingState, setSettingState] = useState(false);
  const dispatch = useDispatch();

  const mainMenu = [
    {
      key: "rol-n-permission",
      title: "Role and Permission",
      icon: EditOutlined,
      path: `${APP_PREFIX_PATH}/role`,
      breadcrumb: true,
      permission: roleState,
      submenu: [],
    },
    {
      key: "manage-staff",
      title: "Manage Staff",
      icon: SolutionOutlined,
      path: `${APP_PREFIX_PATH}/staff`,
      breadcrumb: true,
      permission: staffState,
      submenu: [],
    },
    {
      key: "contact_us",
      title: "Contact Us",
      icon: ContactsOutlined,
      path: `${APP_PREFIX_PATH}/contact-us`,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: "change_pwd",
      title: "Change Password",
      icon: KeyOutlined,
      path: `${APP_PREFIX_PATH}/change-password`,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: "settings",
      title: "Settings",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/settings`,
      breadcrumb: true,
      submenu: [],
      permission: settingState
    },
  ];
  const history = useHistory();
  const signOut = () => {
    localStorage.clear();
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem("per_page");
    localStorage.removeItem("user_detail");
    localStorage.removeItem("permission");
    localStorage.removeItem('role_slag');
    localStorage.removeItem('pickUpKey');
    history.push("/");
    dispatch(authenticated(null))
  };

  useEffect(() => {
    let value = { staff: false,setting:false, role:false  }
    if(!permission?.length)
    {
      const getPermission = async () => {
        getPermissionList.data = JSON.stringify('');
        const permissionResponse = await getDataFromApi(getPermissionList);
        localStorage.setItem("permission", JSON.stringify(permissionResponse?.data?.data));
        // eslint-disable-next-line
        permissionResponse?.data?.data.map((single) => {
          if (single.permission_id.name === "roles-list") {
            value.role= true
          }
          else if (single.permission_id.name === "users-list") {
            value.staff= true
          }
          else if (single.permission_id.name === "booking-restrictions-create"|| single.permission_id.name === "payment-setting-list"|| single.permission_id.name === "tax-setting-list"
              || single.permission_id.name === "trip-status-list" || single.permission_id.name === "expense-type-list"
              || single.permission_id.name === "miscellaneous-type-list") {
            value.setting = true
          }
        });
        setPermission(permissionResponse?.data?.data)
        dispatch(storePermissionInformation(permissionResponse?.data?.data));
      };
      getPermission();
    }
    else{
      permission && permission?.forEach((single) => {
        if (single.permission_id.name === "roles-list") {
          value.role= true
        }
        else if (single.permission_id.name === "users-list") {
          value.staff= true
        }
        else if (single.permission_id.name === "booking-restrictions-create"|| single.permission_id.name === "payment-setting-list"|| single.permission_id.name === "tax-setting-list"
            || single.permission_id.name === "trip-status-list" || single.permission_id.name === "expense-type-list"
            || single.permission_id.name === "miscellaneous-type-list") {
          value.setting = true
        }
      });
    }
    setSettingState(value.setting);
    setStaffState(value.staff);
    setRoleState(value.role);
  }, [permission,setSettingState,setStaffState,setRoleState]);



  const editprofile = () => {
    history.push(`${APP_PREFIX_PATH}/edit-profile`);
  };
  const profileImg = profile.profile_pic;

  const renderMenu = (menu,i) =>{
  if(menu.key === 'rol-n-permission' || menu.key === 'manage-staff' || menu.key==='settings'){
    if( menu.permission ){
          return(
              <Menu.Item className="profile_update" key={i}>
                <Link to={menu.path}>
                <Icon className="mr-3" type={menu.icon} />
                <span className="font-weight-normal">{menu.title}</span>
              </Link>
              </Menu.Item>)
    }
  }else{
    return(
        <Menu.Item className="profile_update" key={i}>
        <Link to={menu.path}>
      <Icon className="mr-3" type={menu.icon} />
      <span className="font-weight-normal">{menu.title}</span>
    </Link>
        </Menu.Item>)
    }
  }
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">
              {profile.first_name + " " + profile.last_name}
            </h4>
            <span className="text-muted">{profile.role_id.name}</span>
            <span style={{ marginLeft: "10px" }}>
              <EditOutlined onClick={editprofile} />
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu
          onClick={() => {
            localStorage.removeItem("page");
          }}
        >
          {mainMenu.map((el, i) => renderMenu(el,i))}
          <Menu.Item key={mainMenu.legth + 1} onClick={() => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null)(NavProfile);
