import axios from "axios";

export const getDataFromApi = (request) => {
    return new Promise(function (resolve, reject) {
        axios(request)
            .then((response) => {
                if (response.status === 200) {
                    resolve({ data: response.data });
                }
            })
            .catch((err) => {
                resolve({ error: err?.response?.data?.response_msg });
            });
    });
};

export default getDataFromApi;
