import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";

export const APP_NAME = "Weeport";
// export const API_BASE_URL = "http://159.89.169.171:4501/api/v1/";
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
// export const API_BASE_URL = "http://localhost:4501/api/v1/"
export const APP_PREFIX_PATH = "/control-panel";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
};
