import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const routeList = [
  {
    path: `${APP_PREFIX_PATH}/vehicle-type`,
    component: lazy(() => import(`./vehicle/vehicle-type`)),
    permission: "vehicles-list",
  },
  {
    path: `${APP_PREFIX_PATH}/vehicle-type-insert`,
    component: lazy(() => import(`./vehicle/vehicle-insert`)),
    permission: "vehicles-create",
  },
  {
    path: `${APP_PREFIX_PATH}/vehicle-type-edit`,
    component: lazy(() => import(`./vehicle/vehicle-edit`)),
    permission: "vehicles-update",
  },
  {
    path: `${APP_PREFIX_PATH}/add-on`,
    component: lazy(() => import(`./add-on/add-on`)),
    permission: "addons-list",
  },
  {
    path: `${APP_PREFIX_PATH}/add-on-insert`,
    component: lazy(() => import(`./add-on/add-on-insert`)),
    permission: "addons-create",
  },
  {
    path: `${APP_PREFIX_PATH}/add-on-edit`,
    component: lazy(() => import(`./add-on/add-on-edit`)),
    permission: "addons-update",
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare`,
    component: lazy(() => import(`./base-fare/base-fare`)),
    permission: "vehicles-list",
  },
  {
    path: `${APP_PREFIX_PATH}/edit-base-fare`,
    component: lazy(() => import(`./base-fare/edit-basefare`)),
    permission: "vehicles-update",
  },
  // {
  //   path:`${APP_PREFIX_PATH}/base-fare-vehicletype`,
  //   component:lazy(() => import(`./base-fare/basefare-vehicletype`)),
  //   permission:'basefarecity-list'
  // },
  {
    path: `${APP_PREFIX_PATH}/base-fare/base-fare-vehicletype`,
    component: lazy(() =>
      import(`views/app-views/base-fare/basefare-vehicle-location`)
    ),
    permission: "basefarelocation-list",
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-add-checkpoint`,
    component: lazy(() =>
      import(`views/app-views/base-fare/add-update-checkpoint`)
    ),
    permission: "basefarecheckpoint-create",
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-edit-checkpoint`,
    component: lazy(() =>
      import(`views/app-views/base-fare/add-update-checkpoint`)
    ),
    permission: "basefarecheckpoint-update",
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-add-pickPoint`,
    component: lazy(() =>
      import(`views/app-views/base-fare/add-update-location`)
    ),
    permission: "basefarelocation-create",
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-update-pickPoint`,
    component: lazy(() =>
      import(`views/app-views/base-fare/add-update-location`)
    ),
    permission: "basefarelocation-update",
  },
  // {
  //   path:`${APP_PREFIX_PATH}/addfare-on-radius`,
  //   component:lazy(() => import(`./base-fare/addfare-on-radius`)),
  //   permission:'basefarecheckpoint-update'
  // },
  {
    path: `${APP_PREFIX_PATH}/goods`,
    component: lazy(() => import(`./goods-type/goods`)),
    permission: "goods-list",
  },
  {
    path: `${APP_PREFIX_PATH}/goods-add`,
    component: lazy(() => import(`./goods-type/goods-add`)),
    permission: "goods-create",
  },
  {
    path: `${APP_PREFIX_PATH}/goods-edit`,
    component: lazy(() => import(`./goods-type/goods-edit`)),
    permission: "goods-update",
  },
  {
    path: `${APP_PREFIX_PATH}/coupons`,
    component: lazy(() => import(`./coupons/coupons`)),
    permission: "coupon-list",
  },
  {
    path: `${APP_PREFIX_PATH}/coupons-insert`,
    component: lazy(() => import(`./coupons/coupons-insert`)),
    permission: "coupon-create",
  },
  {
    path: `${APP_PREFIX_PATH}/coupons-edit`,
    component: lazy(() => import(`./coupons/coupons-edit`)),
    permission: "coupon-update",
  },
  {
    path: `${APP_PREFIX_PATH}/credit`,
    component: lazy(() => import(`./credit-type/credit`)),
    permission: "credits-list",
  },
  {
    path: `${APP_PREFIX_PATH}/credit-insert`,
    component: lazy(() => import(`./credit-type/credit-insert`)),
    permission: "credits-create",
  },
  {
    path: `${APP_PREFIX_PATH}/credit-edit`,
    component: lazy(() => import(`./credit-type/credit-edit`)),
    permission: "credits-update",
  },
  {
    path: `${APP_PREFIX_PATH}/states`,
    component: lazy(() => import(`./states`)),
    permission: "states-list",
  },
  {
    path: `${APP_PREFIX_PATH}/states-add`,
    component: lazy(() => import(`./states/states-add`)),
    permission: "states-create",
  },
  {
    path: `${APP_PREFIX_PATH}/states-edit`,
    component: lazy(() => import(`./states/states-add`)),
    permission: "states-update",
  },
  {
    path: `${APP_PREFIX_PATH}/districts`,
    component: lazy(() => import(`./districts`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/districts-add`,
    component: lazy(() => import(`./districts/districts-add`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/districts-edit`,
    component: lazy(() => import(`./districts/districts-add`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/cities`,
    component: lazy(() => import(`./cities/`)),
    permission: "cities-list",
  },
  {
    path: `${APP_PREFIX_PATH}/cities-add`,
    component: lazy(() => import(`./cities/cities-add`)),
    permission: "cities-create",
  },
  {
    path: `${APP_PREFIX_PATH}/cities-edit`,
    component: lazy(() => import(`./cities/cities-add`)),
    permission: "cities-update",
  },
  //   {
  //     path:`${APP_PREFIX_PATH}/districts`,
  //     component:lazy(() => import(`./districts`)),
  //     permission:''
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/districts-add`,
  //     component:lazy(() => import(`./districts/districts-add`)),
  //     permission:''
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/districts-edit`,
  //     component:lazy(() => import(`./districts/districts-add`)),
  //     permission:''
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/cities`,
  //     component:lazy(() => import(`./cities/`)),
  //     permission:'cities-list'
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/cities-add`,
  //     component:lazy(() => import(`./cities/cities-add`)),
  //     permission:'cities-create'
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/cities-edit`,
  //     component:lazy(() => import(`./cities/cities-add`)),
  //     permission:'cities-update'
  //   },

  {
    path: `${APP_PREFIX_PATH}/customers`,
    component: lazy(() => import(`./customers`)),
    permission: "customer-list",
  },
  {
    path: `${APP_PREFIX_PATH}/individual-user`,
    component: lazy(() =>
      import(`./customers/individual-user/individual-user`)
    ),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/customers-corporate-user-add`,
    component: lazy(() => import(`./customers/corporate/add-corporate-user`)),
    permission: "customer-create",
  },
  {
    path: `${APP_PREFIX_PATH}/customers-add-fixed-route`,
    component: lazy(() =>
      import(`./customers/corporate/profile/add-fixed-route`)
    ),
    permission: "customer-create",
  },
  {
    path: `${APP_PREFIX_PATH}/customers-corporate-profile`,
    component: lazy(() => import(`./customers/corporate/profile/user-profile`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/customers-add-individual-user`,
    component: lazy(() =>
      import(`./customers/individual-user/add-individual-user`)
    ),
    permission: "customer-create",
  },
  {
    path: `${APP_PREFIX_PATH}/customers-user-profile`,
    component: lazy(() =>
      import(`./customers/individual-user/profile/user-profile`)
    ),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/update-customers-user-profile`,
    component: lazy(() =>
      import(`./customers/individual-user/profile/individual-user-profile`)
    ),
    permission: "customer-update",
  },
  {
    path: `${APP_PREFIX_PATH}/update-customers-corporate-user-profile`,
    component: lazy(() =>
      import(`./customers/corporate/profile/corporate-individual-user-profile`)
    ),
    permission: "customer-update",
  },
  {
    path: `${APP_PREFIX_PATH}/supervisor`,
    component: lazy(() => import(`./supervisor`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/supervisor-add`,
    component: lazy(() => import(`./supervisor/supervisor-add`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/supervisor-edit`,
    component: lazy(() => import(`./supervisor/supervisor-add`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners`,
    component: lazy(() => import(`./fleet-partners`)),
    permission: "fleet-partners-list",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-vehicle-details`,
    component: lazy(() => import(`./fleet-partners/vehicle-details`)),
    permission: "fleet-partners-list",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-driver-details`,
    component: lazy(() => import(`./fleet-partners/driver_details`)),
    permission: "fleet-partners-list",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-profile`,
    component: lazy(() => import(`./fleet-partners/profile/user-profile`)),
    permission: "fleet-partners-list",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-add`,
    component: lazy(() => import(`./fleet-partners/add-fleetpartner`)),
    permission: "fleet-partners-create",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-edit-vehicle`,
    component: lazy(() => import(`./fleet-partners/edit-vehicle`)),
    permission: "fleet-partners-update",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-booking-details`,
    component: lazy(() => import(`./booking/view-detail`)),
    permission: "fleet-partners-list",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-update`,
    component: lazy(() =>
      import(`./fleet-partners/profile/individual-user-profile`)
    ),
    permission: "fleet-partners-update",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-update-driver`,
    component: lazy(() => import(`./fleet-partners/profile/AddUpdateDriver`)),
    permission: "fleet-partners-update",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-add-driver`,
    component: lazy(() => import(`./fleet-partners/profile/AddUpdateDriver`)),
    permission: "fleet-partners-create",
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-edit-driver`,
    component: lazy(() => import(`./fleet-partners/profile/AddUpdateDriver`)),
    permission: "fleet-partners-update",
  },
  {
    path: `${APP_PREFIX_PATH}/settings`,
    component: lazy(() => import(`./settings`)),
    permission: [
      "booking-restrictions-create",
      "payment-setting-list",
      "tax-setting-list",
      "trip-status-list",
      "expense-type-list",
      "miscellaneous-type-list",
    ],
  },
  {
    path: `${APP_PREFIX_PATH}/role`,
    component: lazy(() => import(`./roles/role`)),
    permission: "roles-list",
  },
  {
    path: `${APP_PREFIX_PATH}/role-add`,
    component: lazy(() => import(`./roles/role-insert`)),
    permission: "roles-create",
  },
  {
    path: `${APP_PREFIX_PATH}/role-edit`,
    component: lazy(() => import(`./roles/role-edit`)),
    permission: "roles-update",
  },
  {
    path: `${APP_PREFIX_PATH}/manage-permission`,
    component: lazy(() => import(`./roles/managePermission`)),
    permission: "permissions-update-roles-permission",
  },
  {
    path: `${APP_PREFIX_PATH}/feedback`,
    component: lazy(() => import(`./feedback/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/terms-and-conditions`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/privacy`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/about`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/payment`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/refunds-and-cancellation-fleet`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/refunds-and-cancellation-customer`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/trip`,
    component: lazy(() => import(`./page/index`)),
    permission: "",
  },
  // Consignment note data routes
  {
    path: `${APP_PREFIX_PATH}/consignment-note-data`,
    component: lazy(() => import(`./consignment-note-data`)),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/consignor-consignee-add`,
    component: lazy(() =>
      import(`./consignment-note-data/consignor-consignee-add`)
    ),
    permission: "",
  },
  {
    path: `${APP_PREFIX_PATH}/consignor-consignee-edit`,
    component: lazy(() =>
      import(`./consignment-note-data/consignor-consignee-add`)
    ),
    permission: "",
  },
  // Customer delete logs routes

  {
    path: `${APP_PREFIX_PATH}/customer-delete-logs`,
    Component: lazy(() => import(`./customers-delete-logs`)),
    permission: "",
  },
];

export const AppViews = () => {
  const auth = useSelector((state) => state.auth);
  const allowedPermission = auth?.roleInformation?.map(
    (role) => role.permission_id.name
  );

  const roleSlag = localStorage.getItem("role_slag");

  const getRoute = (route) => {
    if (roleSlag === "super-admin" || route.permission === "") {
      return (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact
        />
      );
    } else {
      if (route.permission !== "") {
        if (typeof route.permission === "string") {
          if (allowedPermission && allowedPermission.includes(route.permission))
            return (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                exact
              />
            );
        } else {
          if (
            allowedPermission &&
            route?.permission?.some((r) => allowedPermission.includes(r))
          )
            return (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                exact
              />
            );
        }
      }
    }
  };

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboards`))}
          exact
        />
        {routeList.map((route) => getRoute(route))}
        <Route
          path={`${APP_PREFIX_PATH}/addfare-on-radius`}
          component={lazy(() =>
            import(`views/app-views/base-fare/add-update-fare-on-radius`)
          )}
          exact
        />
        {/*<Route*/}
        {/*  path={`${APP_PREFIX_PATH}/editfare-on-radius`}*/}
        {/*  component={lazy(() => import(`./base-fare/editfare-on-radius`))}*/}
        {/*  exact*/}
        {/*/>*/}
        {roleSlag === "supervisor" && (
          <Route
            path={`${APP_PREFIX_PATH}/fleet-partners-add-vehicle`}
            component={lazy(() => import(`./fleet-partners/add-fleetpartner`))}
            exact
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/vehicle-details`}
          component={lazy(() => import(`./fleet-partners/vehicle-details`))}
          exact
        />
        {/* Accessible to all user */}
        {/* <Route
          path={`${APP_PREFIX_PATH}/report`}
          component={lazy(() => import(`./report/report-individual`))}
          exact
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/booking-report`}
          component={lazy(() => import(`./report/booking-report`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/bookings`}
          component={lazy(() => import(`./booking`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/assign-driver`}
          component={lazy(() => import(`./booking/assign-driver`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/bookings-view-detail`}
          component={lazy(() => import(`./booking/view-detail`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/bookings-add-ride`}
          component={lazy(() => import(`./booking/add-ride`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/update-bookings`}
          component={lazy(() => import(`./booking/add-ride`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/staff-add`}
          component={lazy(() => import(`./staff/staff-insert`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/staff`}
          component={lazy(() => import(`./staff/staff`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/staff-edit`}
          component={lazy(() => import(`./staff/staff-update`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/notification`}
          component={lazy(() => import(`./notification/notification`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/notification-details`}
          component={lazy(() => import(`./notification/notification`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/send-notification`}
          component={lazy(() => import(`./notification/send-notification`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/payouts`}
          component={lazy(() => import(`./payout/payouts`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/payment-details`}
          component={lazy(() => import(`./payout/payment-details`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/manage-wallet`}
          component={lazy(() => import(`./wallet/manage-wallet`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/add-wallet`}
          component={lazy(() => import(`./wallet/add-wallet`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/rating`}
          component={lazy(() => import(`./rating/rating`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/support`}
          component={lazy(() => import(`./support/support`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqs`}
          component={lazy(() => import(`./faqs/faqs`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqs-edit`}
          component={lazy(() => import(`./faqs/faqs-edit`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqs-insert`}
          component={lazy(() => import(`./faqs/faqs-insert`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`./edit-profile/edit-profile`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/change-password`}
          component={lazy(() => import(`./change-password/change-password`))}
          exact
        />
        {/* <Route
          path={`${APP_PREFIX_PATH}/report-individual`}
          component={lazy(() => import(`./report/report-individual`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-corporate`}
          component={lazy(() => import(`./report/report-corporate`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-fleet-partner`}
          component={lazy(() => import(`./report/report-fleet-partner`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-booking-individual`}
          component={lazy(() => import(`./report/report-booking-individual`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-booking-corporate`}
          component={lazy(() => import(`./report/report-booking-corporate`))}
          exact
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/map`}
          component={lazy(() => import(`./Map`))}
          exact
        />
        <Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
