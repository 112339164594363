import {
  DashboardOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  ContactsOutlined,
  KeyOutlined,
  NotificationOutlined,
  SettingOutlined,
  FileTextOutlined,
  EditOutlined,
  TagOutlined,
  HomeOutlined,
  LikeOutlined,
  DollarOutlined,
  AccountBookOutlined,
  AppstoreAddOutlined,
  CarOutlined,
  CreditCardOutlined,
  ShoppingCartOutlined,
  RedEnvelopeOutlined,
  EnvironmentOutlined,
  CommentOutlined,
  QuestionCircleOutlined,
  ApartmentOutlined,
  ContainerOutlined,
  FileDoneOutlined,
  SafetyOutlined,
  InfoCircleOutlined,
  TransactionOutlined,
  UserOutlined,
  PayCircleOutlined,
  BranchesOutlined,
  IdcardOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
];

const userNavTree = [
  {
    key: "vehicle-type",
    path: `${APP_PREFIX_PATH}/vehicle-type`,
    title: "sidenav.vehicle-type",
    icon: CarOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "vehicles-list",
  },
  {
    key: "credit",
    path: `${APP_PREFIX_PATH}/credit`,
    title: "sidenav.credit-type",
    icon: CreditCardOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "credits-list",
  },
  {
    key: "add-on",
    path: `${APP_PREFIX_PATH}/add-on`,
    title: "sidenav.add-on",
    icon: AppstoreAddOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "addons-list",
  },
  {
    key: "goods",
    path: `${APP_PREFIX_PATH}/goods`,
    title: "sidenav.goods-type",
    icon: ShoppingCartOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "goods-list",
  },
  {
    key: "base-fare",
    path: `${APP_PREFIX_PATH}/base-fare`,
    title: "sidenav.base-fare",
    icon: RedEnvelopeOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "vehicles-list",
  },
  {
    key: "coupons",
    path: `${APP_PREFIX_PATH}/coupons`,
    title: "sidenav.coupons",
    icon: TagOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "coupon-list",
  },
  {
    key: "states",
    path: `${APP_PREFIX_PATH}/states`,
    title: "sidenav.states",
    icon: HomeOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "states-list",
  },
  {
    key: "districts",
    path: `${APP_PREFIX_PATH}/districts`,
    title: "sidenav.districts",
    icon: BranchesOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "district-list",
  },
  {
    key: "cities",
    path: `${APP_PREFIX_PATH}/cities`,
    title: "sidenav.cities",
    icon: EnvironmentOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "cities-list",
  },
  {
    key: "supervisor",
    path: `${APP_PREFIX_PATH}/supervisor`,
    title: "sidenav.supervisor",
    icon: ApartmentOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "fleet-partners",
    path: `${APP_PREFIX_PATH}/fleet-partners`,
    title: "sidenav.fleet-partners",
    icon: SolutionOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "fleet-partners-list",
  },
  {
    key: "customers",
    path: `${APP_PREFIX_PATH}/customers`,
    title: "sidenav.customers",
    icon: UsergroupAddOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "customer-list",
  },
  {
    key: "customer-delete-logs",
    path: `${APP_PREFIX_PATH}/customer-delete-logs`,
    title: "sidenav.customer-logs",
    icon: UnorderedListOutlined,
    breadcrumb: true,
    submenu: [],
    permission: "",
  },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "sidenav.settings",
    icon: SettingOutlined,
    breadcrumb: true,
    permission: [
      "booking-restrictions-create",
      "payment-setting-list",
      "tax-setting-list",
      "trip-status-list",
      "expense-type-list",
      "miscellaneous-type-list",
    ],
    submenu: [],
  },
  {
    key: "notification",
    path: `${APP_PREFIX_PATH}/notification`,
    title: "sidenav.notification",
    icon: NotificationOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "consignment-note-data",
    path: `${APP_PREFIX_PATH}/consignment-note-data`,
    title: "idenav.consignment-note-data",
    icon: IdcardOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "bookings",
    path: `${APP_PREFIX_PATH}/bookings`,
    title: "sidenav.bookings",
    icon: AccountBookOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "payouts",
    path: `${APP_PREFIX_PATH}/payouts`,
    title: "sidenav.payouts",
    icon: DollarOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "rating",
    path: `${APP_PREFIX_PATH}/rating`,
    title: "sidenav.rating",
    icon: LikeOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  // {
  //   key: "wallet",
  //   path: `${APP_PREFIX_PATH}/manage-wallet`,
  //   title: "sidenav.wallet",
  //   icon: WalletOutlined,
  //   breadcrumb: true,
  //   submenu: [],
  //   showAll: true,
  // },
  {
    key: "support",
    path: `${APP_PREFIX_PATH}/support`,
    title: "sidenav.support",
    icon: CommentOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "faqs",
    path: `${APP_PREFIX_PATH}/faqs`,
    title: "sidenav.faqs",
    icon: QuestionCircleOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  {
    key: "feedback",
    path: `${APP_PREFIX_PATH}/feedback`,
    title: "sidenav.feedback",
    icon: ContainerOutlined,
    breadcrumb: true,
    submenu: [],
    showAll: true,
  },
  // {
  //   key: "cms",
  //   path: `${APP_PREFIX_PATH}/dashboard`,
  //   title: "sidenav.cms",
  //   icon: ClusterOutlined,
  //   breadcrumb: true,
  //   submenu: [],
  // },
  {
    key: "report",
    path: `${APP_PREFIX_PATH}/report`,
    title: "sidenav.reports",
    icon: FileTextOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "report-list",
        path: `${APP_PREFIX_PATH}/report-list`,
        title: "sidenav.reports.report-list",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "booking-report",
            path: `${APP_PREFIX_PATH}/booking-report`,
            title: "sidenav.reports.booking",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          // {
          //   key: 'report-individual',
          //   path: `${APP_PREFIX_PATH}/report-individual`,
          //   title: 'sidenav.reports.report-customer-individual',
          //   icon: '',
          //   breadcrumb: true,
          //   submenu: []
          // },
          // {
          //   key: 'report-corporate',
          //   path: `${APP_PREFIX_PATH}/report-corporate`,
          //   title: 'sidenav.reports.report-customer-Corporate',
          //   icon: '',
          //   breadcrumb: true,
          //   submenu: []
          // },
          // {
          //   key: 'report-fleet-partner',
          //   path: `${APP_PREFIX_PATH}/report-fleet-partner`,
          //   title: 'sidenav.reports.report-fleet-partner',
          //   icon: '',
          //   breadcrumb: true,
          //   submenu: []
          // },
          // {
          //   key: 'report-booking-individual',
          //   path: `${APP_PREFIX_PATH}/report-booking-individual`,
          //   title: 'sidenav.reports.report-booking-individual',
          //   icon: '',
          //   breadcrumb: true,
          //   submenu: []
          // },
          // {
          //   key: 'report-booking-corporate',
          //   path: `${APP_PREFIX_PATH}/report-booking-corporate`,
          //   title: 'sidenav.reports.report-booking-corporate',
          //   icon: '',
          //   breadcrumb: true,
          //   submenu: []
          // },
        ],
      },
    ],
  },
  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "sidenav.pages",
    icon: FileTextOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "terms-and-conditions",
        path: `${APP_PREFIX_PATH}/terms-and-conditions`,
        title: "sidenav.pages.terms-and-conditions",
        icon: FileDoneOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "privacy",
        path: `${APP_PREFIX_PATH}/privacy`,
        title: "sidenav.pages.privacy",
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "about",
        path: `${APP_PREFIX_PATH}/about`,
        title: "sidenav.pages.about",
        icon: InfoCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "payment",
        path: `${APP_PREFIX_PATH}/payment`,
        title: "sidenav.pages.payment",
        icon: PayCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "trip",
        path: `${APP_PREFIX_PATH}/trip`,
        title: "sidenav.pages.trip",
        icon: CarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "refunds-cancellation",
        path: `${APP_PREFIX_PATH}/refunds-and-cancellation`,
        title: "sidenav.pages.refunds-and-cancellation",
        icon: TransactionOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "refunds-cancellation-customer",
            path: `${APP_PREFIX_PATH}/refunds-and-cancellation-customer`,
            title: "sidenav.pages.refunds-and-cancellation-customer",
            icon: UserOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "fleet-refunds-cancellation",
            path: `${APP_PREFIX_PATH}/refunds-and-cancellation-fleet`,
            title: "sidenav.pages.refunds-and-cancellation-fleet",
            icon: SolutionOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    title: "sidenav.role-n-permission",
    icon: EditOutlined,
    path: `${APP_PREFIX_PATH}/role`,
    breadcrumb: true,
    mainMenu: true,
    submenu: [],
    permission: "roles-list",
  },
  {
    title: "sidenav.manage-staff",
    icon: SolutionOutlined,
    path: `${APP_PREFIX_PATH}/staff`,
    breadcrumb: true,
    submenu: [],
    mainMenu: true,
  },
  {
    title: "sidenav.contact-us",
    icon: ContactsOutlined,
    path: `${APP_PREFIX_PATH}/contact-us`,
    breadcrumb: true,
    submenu: [],
    mainMenu: true,
  },
  {
    title: "sidenav.change-password",
    icon: KeyOutlined,
    path: `${APP_PREFIX_PATH}/change-password`,
    mainMenu: true,
    breadcrumb: true,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree, ...userNavTree];

export default navigationConfig;
