import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/forgot-password-otp`}
          component={lazy(() => import(`./authentication/otp`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/reset-password`}
          component={lazy(() => import(`./authentication/reset-password`))}
          exact
        />
        <Route
            path={`/privacy`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
            path={`/terms-and-conditions`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
            path={`/about`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
            path={`/refunds-and-cancellation-fleet`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
            path={`/payment`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
            path={`/refunds-and-cancellation-customer`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
            path={`/trip`}
            component={lazy(() => import(`./authentication/pages`))}
            exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/error-1`}
          component={lazy(() => import(`./errors/error-page-1`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/error-2`}
          component={lazy(() => import(`./errors/error-page-2`))}
          exact
        />
        <Redirect to={`${APP_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
