import React from 'react'
import { useThemeSwitcher } from "react-css-theme-switcher";

import AuthViews from 'views/auth-views';
import Loading from 'components/shared-components/Loading';

export const AuthLayout = () => {
	const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

	return (
		<div className="auth-container">
			<AuthViews />
		</div>
	)
}


export default AuthLayout
